import { render, staticRenderFns } from "./ResetPassword.vue?vue&type=template&id=2fd7ecfa&scoped=true&"
import script from "./ResetPassword.vue?vue&type=script&lang=js&"
export * from "./ResetPassword.vue?vue&type=script&lang=js&"
import style0 from "./ResetPassword.vue?vue&type=style&index=0&id=2fd7ecfa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fd7ecfa",
  null
  
)


    import installComponents from "!/builds/rentmagic/rentmagic.website.vue.vub/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BAlert, BCol, BFormInput, BFormGroup, BButton, BRow, BForm, BCard, BContainer} from 'bootstrap-vue'
    installComponents(component, {BAlert, BCol, BFormInput, BFormGroup, BButton, BRow, BForm, BCard, BContainer})
    

export default component.exports